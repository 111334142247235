<template>
  <v-container fluid>
    <v-card class="mb-6">
      <v-card-title class="headline">
        Fleet Transition Dashboard
        <v-spacer />
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              class="ml-2"
              v-on="on"
            >
              <v-icon left>
                mdi-calendar
              </v-icon>
              {{ selectedDateRange }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(range, index) in dateRanges"
              :key="index"
              @click="selectedDateRange = range"
            >
              <v-list-item-title>{{ range }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-card outlined>
              <v-card-text>
                <div class="text-overline mb-1">
                  Total Vehicles
                </div>
                <div class="text-h4 mb-2">
                  {{ fleetStats.totalVehicles }}
                </div>
                <v-progress-linear
                  :value="fleetStats.evPercentage"
                  color="success"
                  height="10"
                />
                <div class="caption mt-1">
                  {{ fleetStats.evPercentage }}% EV Transition
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-card outlined>
              <v-card-text>
                <div class="text-overline mb-1">
                  CO2 Reduction
                </div>
                <div class="text-h4 mb-2">
                  {{ fleetStats.co2Reduction }}t
                </div>
                <v-icon
                  color="green"
                  large
                >
                  mdi-leaf
                </v-icon>
                <span class="caption green--text">
                  {{ fleetStats.co2ReductionPercentage }}% reduction
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-card outlined>
              <v-card-text>
                <div class="text-overline mb-1">
                  Cost Savings
                </div>
                <div class="text-h4 mb-2">
                  ${{ fleetStats.costSavings }}k
                </div>
                <v-icon
                  color="blue"
                  large
                >
                  mdi-currency-usd
                </v-icon>
                <span class="caption blue--text">
                  {{ fleetStats.costSavingsPercentage }}% reduction in TCO
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-card outlined>
              <v-card-text>
                <div class="text-overline mb-1">
                  Charging Stations
                </div>
                <div class="text-h4 mb-2">
                  {{ fleetStats.chargingStations }}
                </div>
                <v-icon
                  color="amber"
                  large
                >
                  mdi-ev-station
                </v-icon>
                <span class="caption">
                  {{ fleetStats.chargingStationsAvailability }}% available
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <v-card height="400">
          <v-card-title>Fleet Transition Progress</v-card-title>
          <v-card-text>
            <apexchart
              type="line"
              height="300"
              :options="transitionChartOptions"
              :series="transitionChartSeries"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card height="400">
          <v-card-title>Vehicle Type Distribution</v-card-title>
          <v-card-text class="d-flex justify-center align-center">
            <apexchart
              type="donut"
              height="300"
              :options="distributionChartOptions"
              :series="distributionChartSeries"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-title>
            Cost Comparison
            <v-spacer />
            <v-btn-toggle
              v-model="costView"
              mandatory
            >
              <v-btn
                small
                value="monthly"
              >
                Monthly
              </v-btn>
              <v-btn
                small
                value="yearly"
              >
                Yearly
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
          <v-card-text>
            <apexchart
              type="bar"
              height="250"
              :options="costChartOptions"
              :series="costChartSeries"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-title>
            Emissions Reduction
            <v-spacer />
            <v-btn-toggle
              v-model="emissionsView"
              mandatory
            >
              <v-btn
                small
                value="monthly"
              >
                Monthly
              </v-btn>
              <v-btn
                small
                value="yearly"
              >
                Yearly
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
          <v-card-text>
            <apexchart
              type="area"
              height="250"
              :options="emissionsChartOptions"
              :series="emissionsChartSeries"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mt-6">
      <v-card-title>
        Fleet Vehicles
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="vehicleHeaders"
        :items="vehicles"
        :search="search"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusColor(item.status)"
            dark
            small
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.type="{ item }">
          <v-chip
            :color="item.type === 'EV' ? 'green' : 'grey'"
            dark
            small
          >
            {{ item.type }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            small
            @click="showVehicleDetails(item)"
          >
            <v-icon small>
              mdi-information-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-6">
      <v-card-title>Transition Recommendations</v-card-title>
      <v-card-text>
        <v-timeline dense>
          <v-timeline-item
            v-for="(recommendation, i) in recommendations"
            :key="i"
            :color="recommendation.color"
            small
          >
            <div>
              <div class="font-weight-bold">
                {{ recommendation.title }}
              </div>
              <div>{{ recommendation.description }}</div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="vehicleDialog"
      max-width="600px"
    >
      <v-card v-if="selectedVehicle">
        <v-card-title>
          Vehicle Details
          <v-spacer />
          <v-btn
            icon
            @click="vehicleDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>ID</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ selectedVehicle.id }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Type</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ selectedVehicle.type }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Model</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ selectedVehicle.model }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Status</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ selectedVehicle.status }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-divider class="my-4" />

          <div v-if="selectedVehicle.type === 'EV'">
            <div class="text-h6 mb-2">
              EV Metrics
            </div>
            <v-row>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Battery Health</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ selectedVehicle.batteryHealth }}%
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Range</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ selectedVehicle.range }} miles
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12">
                <div class="text-subtitle-2 mb-1">
                  Charge Level
                </div>
                <v-progress-linear
                  :value="selectedVehicle.chargeLevel"
                  color="green"
                  height="20"
                >
                  <template v-slot:default="{ value }">
                    <strong>{{ value }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
            </v-row>
          </div>

          <div v-else>
            <div class="text-h6 mb-2">
              ICE Metrics
            </div>
            <v-row>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Fuel Level</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ selectedVehicle.fuelLevel }}%
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>MPG</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ selectedVehicle.mpg }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Emissions</v-list-item-subtitle>
                    <v-list-item-title>
                      {{ selectedVehicle.emissions }} g/km
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </div>

          <v-divider class="my-4" />

          <div class="text-h6 mb-2">
            Transition Analysis
          </div>
          <v-row>
            <v-col cols="12">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Recommended Action
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ selectedVehicle.recommendedAction }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Potential Savings</v-list-item-subtitle>
                  <v-list-item-title>
                    ${{ selectedVehicle.potentialSavings }}/year
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>CO2 Reduction</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ selectedVehicle.co2Reduction }}
                    tons/year
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="vehicleDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            v-if="selectedVehicle.type === 'ICE'"
            color="success"
            @click="planTransition"
          >
            Plan Transition
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "FleetTransitionDashboard",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      search: "",
      vehicleDialog: false,
      selectedVehicle: null,
      costView: "monthly",
      emissionsView: "monthly",
      selectedDateRange: "Last 12 Months",
      dateRanges: [
        "Last 30 Days",
        "Last 3 Months",
        "Last 6 Months",
        "Last 12 Months",
        "Year to Date",
      ],
      fleetStats: {
        totalVehicles: 124,
        evCount: 43,
        iceCount: 81,
        evPercentage: 35,
        co2Reduction: 156,
        co2ReductionPercentage: 28,
        costSavings: 87.5,
        costSavingsPercentage: 22,
        chargingStations: 18,
        chargingStationsAvailability: 75,
      },
      vehicleHeaders: [
        { text: "ID", value: "id" },
        { text: "Model", value: "model" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status" },
        { text: "Last Used", value: "lastUsed" },
        { text: "Mileage", value: "mileage" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      vehicles: [
        {
          id: "V-1001",
          model: "BYD Atto 3",
          type: "EV",
          status: "Active",
          lastUsed: "2 hours ago",
          mileage: "12,450",
          batteryHealth: 98,
          range: 310,
          chargeLevel: 85,
          recommendedAction: "Maintain regular service schedule",
          potentialSavings: "3,200",
          co2Reduction: "4.2",
        },
        {
          id: "V-1002",
          model: "Corolla",
          type: "ICE",
          status: "Active",
          lastUsed: "5 hours ago",
          mileage: "34,780",
          fuelLevel: 65,
          mpg: 18,
          emissions: 285,
          recommendedAction: "Replace with F-150 Lightning in Q3",
          potentialSavings: "2,800",
          co2Reduction: "5.7",
        },
        {
          id: "V-1003",
          model: "BYD Seal",
          type: "EV",
          status: "Charging",
          lastUsed: "1 day ago",
          mileage: "8,320",
          batteryHealth: 95,
          range: 220,
          chargeLevel: 45,
          recommendedAction: "Schedule battery check at 10,000 miles",
          potentialSavings: "2,100",
          co2Reduction: "3.8",
        },
        {
          id: "V-1004",
          model: "Honda Civic",
          type: "ICE",
          status: "Maintenance",
          lastUsed: "3 days ago",
          mileage: "56,230",
          fuelLevel: 30,
          mpg: 32,
          emissions: 165,
          recommendedAction: "Replace with Prius Prime in Q2",
          potentialSavings: "1,900",
          co2Reduction: "3.2",
        },
        {
          id: "V-1005",
          model: "Audi e-tron",
          type: "EV",
          status: "Inactive",
          lastUsed: "1 week ago",
          mileage: "15,780",
          batteryHealth: 92,
          range: 240,
          chargeLevel: 20,
          recommendedAction: "Charge and return to service",
          potentialSavings: "2,400",
          co2Reduction: "4.0",
        },
        {
          id: "V-1006",
          model: "Honda Civic",
          type: "ICE",
          status: "Active",
          lastUsed: "12 hours ago",
          mileage: "28,450",
          fuelLevel: 80,
          mpg: 36,
          emissions: 145,
          recommendedAction: "Monitor for replacement in Q4",
          potentialSavings: "1,600",
          co2Reduction: "2.8",
        },
        {
          id: "V-1007",
          model: "BYD Atto 3",
          type: "EV",
          status: "Active",
          lastUsed: "3 hours ago",
          mileage: "5,120",
          batteryHealth: 99,
          range: 320,
          chargeLevel: 72,
          recommendedAction: "Maintain regular service schedule",
          potentialSavings: "3,500",
          co2Reduction: "6.1",
        },
      ],
      recommendations: [
        {
          title: "Replace 15 delivery vans with electric alternatives",
          description: "Potential annual savings of $45,000 and 78 tons of CO2",
          color: "green",
        },
        {
          title: "Install 5 additional charging stations at Warehouse B",
          description:
            "Will support 10 new EVs and reduce charging bottlenecks",
          color: "blue",
        },
        {
          title: "Transition sales fleet to hybrid models by Q3",
          description:
            "Gradual transition with minimal disruption to operations",
          color: "amber",
        },
        {
          title: "Implement driver training for EV operation",
          description: "Maximize range and reduce maintenance costs",
          color: "purple",
        },
      ],
      // ApexCharts options and series
      transitionChartOptions: {
        chart: {
          type: "line",
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: [4, 4, 2],
          dashArray: [0, 0, 5],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          title: {
            text: "Number of Vehicles",
          },
        },
        colors: ["#9E9E9E", "#4CAF50", "#2196F3"],
        fill: {
          type: ["solid", "solid", "solid"],
          opacity: [0.35, 0.35, 0],
        },
        legend: {
          position: "top",
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
      transitionChartSeries: [
        {
          name: "ICE Vehicles",
          data: [100, 98, 95, 92, 90, 87, 85, 82, 80, 78, 75, 73],
        },
        {
          name: "EV Vehicles",
          data: [0, 2, 5, 8, 10, 13, 15, 18, 20, 22, 25, 27],
        },
        {
          name: "Target",
          data: [0, 8, 17, 25, 33, 42, 50, 58, 67, 75, 83, 92],
        },
      ],
      distributionChartOptions: {
        chart: {
          type: "donut",
        },
        labels: ["ICE Vehicles", "EV Vehicles"],
        colors: ["#9E9E9E", "#4CAF50"],
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      distributionChartSeries: [81, 43], // ICE, EV counts
      costChartOptions: {
        chart: {
          type: "bar",
          stacked: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        },
        yaxis: {
          title: {
            text: "Cost ($)",
          },
        },
        colors: ["#9E9E9E", "#4CAF50"],
        legend: {
          position: "top",
        },
      },
      costChartSeries: [
        {
          name: "ICE Fleet Costs",
          data: [12500, 12800, 13100, 12900, 13200, 13500],
        },
        {
          name: "EV Fleet Costs",
          data: [10200, 10100, 10300, 10000, 10200, 10100],
        },
      ],
      emissionsChartOptions: {
        chart: {
          type: "area",
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: [4, 2],
          dashArray: [0, 5],
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        },
        yaxis: {
          title: {
            text: "CO2 Emissions (tons)",
          },
        },
        colors: ["#FF5722", "#2196F3"],
        fill: {
          type: ["gradient", "solid"],
          opacity: [0.3, 0],
        },
        legend: {
          position: "top",
        },
      },
      emissionsChartSeries: [
        {
          name: "Actual Emissions",
          data: [45, 42, 38, 35, 32, 28],
        },
        {
          name: "Projected Emissions",
          data: [45, 40, 35, 30, 25, 20],
        },
      ],
    };
  },
  computed: {
    // Example computed property
    evPercentageFormatted() {
      return `${this.fleetStats.evPercentage}%`;
    },

    // If using Vuex
    // ...mapState('fleet', ['fleetStats', 'vehicles', 'recommendations'])
  },
  mounted() {
    // You might want to fetch data from an API when the component is mounted
    this.fetchFleetData();
  },
  methods: {
    // Add this method to fetch data from an API
    fetchFleetData() {
      // In a real application, you would fetch data from an API
      // For example:
      // this.$store.dispatch('fleet/fetchFleetData');
      // or
      // axios.get('/api/fleet-data').then(response => {
      //   this.fleetStats = response.data.stats;
      //   this.vehicles = response.data.vehicles;
      //   this.updateCharts();
      // });
      // For now, we'll just use the mock data already defined
    },

    // Add this method to update charts when data changes
    updateCharts() {
      // Update distribution chart with current data
      this.distributionChartSeries = [
        this.fleetStats.iceCount,
        this.fleetStats.evCount,
      ];

      // You could also update other charts here
    },
    getStatusColor(status) {
      switch (status) {
        case "Active":
          return "green";
        case "Charging":
          return "blue";
        case "Maintenance":
          return "orange";
        case "Inactive":
          return "grey";
        default:
          return "grey";
      }
    },
    showVehicleDetails(vehicle) {
      this.selectedVehicle = vehicle;
      this.vehicleDialog = true;
    },
    planTransition() {
      // This would open a transition planning workflow
      this.$nextTick(() => {
        this.vehicleDialog = false;
        this.$nextTick(() => {
          // Show a snackbar or another dialog for transition planning
          // This is just a placeholder for the actual implementation
          alert(`Planning transition for vehicle ${this.selectedVehicle.id}`);
        });
      });
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 8px;
}
.headline {
  font-weight: 500;
}
</style>
